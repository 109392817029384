@import "../../../variables";

.license {

  .status {

    .valid,
    .invalid {
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }

    .valid {
      color: $color-success;
    }

    .invalid {
      color: $color-error;
    }
    margin-bottom: 1rem;
  }

  .details {

    text-align: left;

    ul {
      font-size: $font-size-discreet;

      &.secondary {
        padding-left: 1.5rem;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-dark-text-secondary;
        }
      }

      li {
        font-weight: bold;

        .value {
          vertical-align: middle;
          margin-left: 0.8rem;
          font-weight: normal;
          //font-size: initial;

          #{$theme-dark} & {
            color: $color-primary;
          }

          #{$theme-light} & {
            color: $color-primary;
          }
        }

        .expiration-date {
          #{$theme-dark} & {
            color: $color-dark-text-secondary;
          }

          #{$theme-light} & {
            color: $color-dark-text-secondary;
          }
        }
      }
    }
  }
}

.license-message {
  padding: 1rem 0;
  font-size: initial;

  &.error {
    color: $color-error;
  }

  &.success {
    color: $color-success;
  }
}

.dropzone {
  padding: 2rem 1rem;
  border-radius: 5px;
  cursor: pointer;

  #{$theme-dark} & {
    color: $color-dark-text-secondary;
    border: 1px dashed $color-dark-text-secondary;

    &.target {
      border: 1px solid $color-primary;
      color: $color-primary;
    }
  }

  #{$theme-light} & {
    color: $color-light-text-secondary;
    border: 1px dashed $color-light-text-secondary;

    &.target {
      border: 1px solid $color-primary;
      color: $color-primary;
    }
  }

  &.error {
    border: 1px solid $color-error;
    color: $color-error;
  }

  .placeholder {
    font-size: 1.2rem;
  }
}

.filename {
  font-size: initial;

  #{$theme-dark} & {
    color: $color-dark-text-secondary;
  }

  #{$theme-light} & {
    color: $color-light-text-secondary;
  }

}

