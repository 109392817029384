@import "../../../variables";

.empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .empty {
    text-align: center;

    #{$theme-dark} & {
      color: $color-dark-text-secondary !important;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary !important;
    }

    .aw-icon {
      font-size: 8rem !important;

      svg {
        height: 8rem !important;
        width: 8rem !important;
      }
    }
    .text {
      font-size: 1.5rem !important;
      font-family: $font-family-header;
    }
  }
}