@import "../../../variables";
@import "./logs/index";
@import "./system/index";


.tools {
  padding: 1rem 0;
  height: 100%;

  .container,
  .row,
  .col,
  .tools-section {
    height: 100%;
  }

  .header {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 40px;

    > .title {
      font-size: 1.3rem;
      font-weight: 600;
      flex: 1 1 auto;
      font-family: $font-family-header;
    }

    .actions {
      flex: 0 0 auto;

      .search {
        border-radius: 15px;
        max-width: 150px;
        display: inline-block;
      }

      .btn {
        margin-top: 1px;
      }
    }
  }

  .content {
    height: calc(100% - 100px);
    overflow-y: auto;
    padding-right: 0.5rem;
  }
}

.title2 {
  margin-bottom: 0.3rem;
  font-size: 1.1rem;
  font-weight: 600;
  flex: 1 1 auto;
  font-family: $font-family-header;
  display: flex;
  align-items: baseline;
  gap: 10px;
}