@import "../../../variables";

#aw-sidebar {
  height: 100%;
  padding-top: calc(#{$navbar-height} + 0.5rem);
  z-index: 1000;
  color: $color-nav-text;

  .nav {
    margin-bottom: 0;

    .nav-item {
      padding: 0.5rem 0.3rem;
      background-color: $color-nav-bg;

      .nav-link {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 1.2rem;
        color: $color-nav-text;

        .aw-icon {
          //vertical-align: middle;
          margin-right: $spacer / 2;
          color: $color-nav-text;
        }

        &.secondary {
          font-size: 1rem;
        }
      }

      &.active {
        background-color: $color-nav-bg-active;

        .nav-link,
        .aw-icon {
          color: $color-nav-text-active;
        }
      }
    }

    &.sub {
      padding-left: 3 * $spacer;

      .nav-item {
        padding: 0.3rem 0.3rem;

        .nav-link {
          font-size: 1rem;

          color: $color-nav-text;
        }

        &.active {
          background-color: $color-nav-bg-active;

          .nav-link,
          .aw-icon {

            #{$theme-dark} & {
              color: $color-primary;
            }

            #{$theme-light} & {
              color: $color-primary;
            }
          }
        }
      }
    }
  }

}