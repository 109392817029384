@import "../../../variables";

.aw-input-type {
  cursor: pointer;
  max-width: 100%;
  width: auto;
  vertical-align: middle;

  &.aw-air {
    background-color: $color-device-air;
  }

  &.aw-he4000 {
    background-color: $color-device-he4000;
  }

  &.aw-rack {
    background-color: $color-device-rack-series;
  }

  &.aw-mojopro-app {
    background-color: $color-device-mojopro-app;
  }

  &.aw-mojopro-laptop {
    background-color: $color-device-mojopro-laptop;
  }

  &.aw-pro {
    background-color: $color-device-pro;
  }

  &.aw-studio {
    background-color: $color-device-studio;
  }

  &.aw-default {
    background-color: $color-profile;
  }
}