@import "../../../../../variables";

$grid-item-size: 160px;
$thumb-width: calc(#{$grid-cell-height} - 2 * #{$grid-cell-padding} - 2 * #{$grid-cell-border});
$thumb-height: calc(#{$thumb-width} * 9 / 16);

.multiview {
  .compact {
    display: flex;
    flex-direction: column;
    .actions {
      margin-top: auto;
      margin-bottom: .75rem;
    }
  }

  .aw-control-grid.inline &.collapsed .compact{
    flex-direction: unset;
  }

  .aw-control-grid.inline &.aw-control-grid-item.collapsed {
    .header .controls {
      width: 100%;
    }
  }

  .additional-content {
    align-items: center;
    justify-content: center;
    height: calc(100% - 25px);
  }

  .aw-control-grid.fullsize & .compact{
    height: auto;
  }
}


.audio-select {
  display: flex;
}

.vu_resize {
  column-count: 2;
}  

.vu_right{
  transform: scaleX(-1);
}

.multiview-grid {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;

  .controls {

    .rows,
    .columns,
    .presentation {
      margin-top: 0.5rem;
      display: inline-block;
      vertical-align: middle;

      .label {
        margin-right: 0.5rem;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-dark-text-secondary;
        }

      }

      .value {
        display: inline-block;
        padding: 0 0.2rem;
        vertical-align: middle;
      }
    }

    .presentation {
      label {
        margin-left: 2rem;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }

      }
    }

    .rows {
      margin-right: 1rem;
    }
  }

  .grid {
    flex: 0 1 auto;
    margin: auto;
    background-color: $color-gray-05;
  }

  .grid-item {
    position: relative;
    border:$color-gray-04 1px solid ;
  
    .grid-item-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .grid-item-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;
      background-color: $color-overlay-bg;
      color: $color-overlay-text;
      display: flex;
      align-items: center;
      z-index: 3;

      .audio-selection {
        flex: 1 1 auto;
        text-align: right;
      }

      .aw-input-selector {
        .text-clip {
          max-width: 70px;
        }
      }
    }

    .thumb {
      width: $thumb-width;
      height: $thumb-height;

      #{$theme-dark} & {
        background: $color-dark-border-box;
      }

      #{$theme-light} & {
        background: $color-light-border-box;
      }

      img {
        width: 100%;
        max-height: $thumb-height;
      }
    }

    &.audible {
      #{$theme-dark} & {
        border: 1px solid $color-primary;
      }

      #{$theme-light} & {
        border: 1px solid $color-primary;
      }
    }

    &.light {
      .grid-item-header,
      .grid-item-footer {
        display: none;
      }
    }

    &.light:hover {
      .grid-item-header {
        display: block;
      }
      .grid-item-footer {
        display: flex;
      }
    }
  }
}

.aw-input-selector-popup.aw-popup {
  z-index: 1100;
}

.thumb-warning {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: $thumb-width;
  height: $thumb-height;
  text-align: left;
  overflow: hidden;
  color: $color-gray-05;
  position: absolute;
  background-color: rgba($color-yellow-01, .8);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}


.thumb-error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: $thumb-width;
  height: $thumb-height;
  text-align: left;
  overflow: hidden;
  color: $color-gray-05;
  position: absolute;
  background-color: rgba($color-error, .8);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}