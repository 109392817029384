@import "../../../../variables";

.sdi-order {
  padding: 0.5rem;

  .sdi-icon {
    display: inline-block;
    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }

    &.ref {
      margin-right: 1rem;
    }

    &.active {
      #{$theme-dark} & {
        color: $color-primary;
      }

      #{$theme-light} & {
        color: $color-primary;
      }
    }

    svg.aw-icon {
      height: 2em;
    }
  }

  .sdi-name {
    font-size: $font-size-discreet;
    margin-left: 0
  }
}

.form-advanced-section{
 background-color: transparent !important;
}