@import "../../../variables";
@import "./general/index";
@import "./inputs/index";
@import "./outputs/index";
@import "./file-transfer/index";
@import "./intercom/index";
@import "./videocards/index";

.settings {
  padding: 0;
  height: 100%;

  &.settings-modal {
    height: calc(100% - 40px);
    overflow: auto;
    padding-right: 0.5rem;
  }

  .container,
  .row,
  .col,
  .settings-section {
    height: calc(100% - 10px);
  }

  .form-advanced-section {

    #{$theme-dark} & {
      background-color: $color-dark-bg-box;
      border-color: $color-dark-border-box;
    }

    #{$theme-light} & {
      background-color: $color-light-bg-box;
      border-color: $color-light-border-box;
    }

    .title,
    .aw-icon {
      #{$theme-dark} & {
        color: $color-dark-text-secondary;
      }
      #{$theme-light} & {
        color: $color-light-text-secondary;
      }
    }
  }

  .header {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    height: 40px;

    .title {
      font-size: 1.3rem;
      font-weight: 600;
      flex: 1 1 auto;
      font-family: $font-family-header;

      &.secondary {
        font-size: 1rem;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }

      .subtitle {
        font-family: $font-family-base;
        font-size: $font-size-discreet;
        font-weight: normal;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }
    }

    .actions {
      display: flex;
      flex: 0 0 auto;

      .search {
        border-radius: 15px;
        max-width: 150px;
        display: inline-block;
      }

      .btn {
        margin-left: 0.5rem;
      }
    }
  }

  .content {
    height: calc(100% - 50px);
    overflow-y: auto;

    &.with-menu {
      height: calc(100% - 90px);
    }
  }
}


.profiles-settings {
  background-color: transparent;

  .profile {
    display: flex;
    flex-direction: row;
    min-height: 60px;
    border-radius: 8px;
    border: transparent 2px solid;

    +.profile {
      margin-top: calc(#{$spacer}/2);
    }

    >div {
      @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
      padding: calc(#{$spacer}/2);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px 0 0 8px;

      +div {
        margin-left: calc(#{$spacer}/8);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &:hover{
      border: $color-primary 2px solid;

      >div {
        background-color: $color-gray-06;
      }
    }

    &.disabled{
      >div {
        background-color: $color-gray-06;
        color: $color-gray-02;
      }
    }

    .name {
      flex: 5 1;
    }

    .linked-input {
      width: 50px;
    }

    .protocol {
      width: 90px;
      text-transform: uppercase;
      > .aw-icon {
        font-size: 1rem;
      }

      &.enc {
        width: 100px;
      }
    }

    >.status {
      width: 10%;
    }

    .encoder-preset {
      flex: 5 1;
      justify-content: flex-start;
    }

    .format {
      flex: 5 1;
      justify-content: flex-start;
    }

    .metadata {
      @include aw-theme(background-color, transparent, transparent);
      padding: 0;
      width: 45%;
      justify-content: flex-start;

      .metadata-container {
        display: flex;
        position: relative;
        min-width: 0;

        .metadata-value {
          position: absolute;
          max-width: 100%;
          width: 100%;
        }
      }
    }

    .selection {
      border-radius: 8px 0 0 8px;
      width: 5%;
    }

    .actions {
      border-radius: 0 8px 8px 0;
      width: 5%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

$protocol-size: 150px;

.new-profile {
  height: calc(100vh - #{$navbar-height} - #{$spacer});
  max-width: calc(5 * #{$protocol-size});
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  > .protocol {
    width: $protocol-size;
    height: $protocol-size;
    font-size: 1.5rem;

    i.aw-icon {
      line-height: 1;
    }
  }
}

.form-section {
  .protocol {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    font-size: 2rem;
  }
}