@import "../../../../variables";

#ndiStreamMode {
  display: flex;
  align-items: center;

  .manual,
  .discovery {
    padding: 0 0.5rem;
    flex: 1 1 auto;
  }

  .manual {
    text-align: right;
  }
  .aw-switch {
    flex: 0 0 auto;
  }
}

.push-pull {
  display: flex;
  align-items: center;

  .push,
  .pull {
    padding: 0 0.5rem;
    flex: 1 1 auto;
  }

  .push {
    text-align: right;
  }
  .aw-switch {
    flex: 0 0 auto;
  }

  .switch .switch-checkbox + .switch-label {
    background-color: $color-gray-01;
    border-color: $color-gray-01;
  }

  .switch .switch-checkbox + .switch-label:before {
    border-color: $color-gray-01;
  }
}

.uri-preview {
  margin: 1rem 0;
  text-align: center;

  .uri {
    font-size: $font-size-discreet;
    color: $color-dark-text-secondary;
  }
}
