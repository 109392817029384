@import "../../../variables";

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  background-color: black;

  .error-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    text-align: center;

    .error {
      flex: 1 1 auto;
    }
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    &.error {
      display: none;
    }
  }
}
