@import "../../../variables";

.login {
  .icon {
    text-align: center;
    margin-bottom: $spacer;
    .aw-icon {
      font-size: 60px;
      margin-bottom: $spacer;
    }
  }

  .logo-aviwest {
    margin-top: $spacer;
    text-align: center;
  }
}

#auth-layout #auth-body .side-content .baseline {
  font-weight: unset;

  .tagline {
    margin-top: 2rem;
  }
  @include media-breakpoint-down(md) {
    svg {
      width: 200px;
    }
  }
}
