.aw-encoder {
  .thumbnail .thumb-warning,
  .thumbnail .thumb-error {
    text-align: center;
    font-size: 14px !important;
  }

  .portrait{
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
  }

  .aw-control-grid.inline &.aw-control-grid-item.collapsed {
    .header .controls {
      width: 100%;
      button.active {
        background-color: $primary !important;
      }
    }
    .content .main-content .compact {
      padding-left: $spacer;
    }
  }

  &.new {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    cursor: pointer;

    .aw-control-grid.inline &.collapsed {
      font-size: 1.75em !important;
    }
  }

  .content-title {
    .aw-icon {
      font-size: 14px;
      vertical-align: top;
    }
  }

  .main-content .details {
    margin-bottom: .25rem;

    .audio-levels {
      margin-bottom: .75rem;
    }
  }
}