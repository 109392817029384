@import "../../../../variables";

.volume-slider {
  height: 100px;
  min-width: 30px;
  display: inline-block;
  margin: 0 0.5rem;
  text-align: center;
  font-size: $font-size-discreet;

  .value {
    font-weight: bold;
    color: $color-primary;
  }
  .name {
    color: $color-dark-text-secondary;
  }

  .rc-slider {
    margin: auto;
    height: calc(100% - 38px);
  }
}

.vertical_routing{
  transform-origin: 0 0;
  transform: rotate(-90deg);
  margin-top: 4em;
}

table.routing td:not(.background_routing):not(.aes67):not(.aes67b):not(.aes67check){
  text-align: start;
  vertical-align: initial;
  min-width: 1em;
}

table.routing tr{
  height: 2em;
}
.aes67{
  width: 9em;
}

.aes67label{
    width: 10em !important;
  padding-left: 1em;
  padding-left: 1em;
  width: 150px;
    padding-left: 1em;
  width: 150px;
}
.aes67check{
     position: relative !important;
     vertical-align: top ;
     padding-left: 2em;
     width: 1px;
}

.aes67 Button{
  display: block;
  margin: auto;
}

.center{
  text-align: center;
  vertical-align: middle;
}

.line_routing {
  width: 110px;
  height: 47px;
  border-bottom: 1px solid white;
  transform: translateY(-1px) translateX(-20px) rotate(-25deg);
  position: absolute;
  z-index: -1;
}

.bottom_routing {
  position: absolute;
  bottom: 20px;
  left: 46px;
  height: 1em;
  width: 50%;
}

.top_routing {
  position: absolute;
  top: 5px;
  left:0px;
  width: 1em;
}

.background_routing {
  width: 100px;
  height: 80px;
  padding: 0;
  margin: 0;
}
.background_routing>div {
  position: relative;
  height: 100%;
  width: 100%;
  margin-left: 10px;
  top: 0;
  left: 0;
}

table.routing tr:not(.label_routing):not(.aes67):not(.aes67check):not(.aes67){
  border: transparent 2px solid;

  &:hover{
    background-color: $color-gray-06 !important;
    td{
      border-top: $primary 2px solid;
      border-bottom: $primary 2px solid;
      }
    td:first-child{
      border-left: $primary 2px solid;
    }
    td:last-child{
      border-right: $primary 2px solid;
    }
  }
}
 .label_right_routing{
  text-align: left;
  vertical-align: bottom;
}

table.routing{
  table-layout: fixed;
  width: 100%;
}

.flex-left{
    display: flex;
    justify-content: flex-start !important;
}