.ip-output-profiles-settings-modal {
  line-height: 1.5;
  nav {
    display: inline-block;
  }

  .actions {
    display: inline;
    margin-left: $spacer;

    form {
      display: inline;
    }

    button {
      margin-left: $spacer;
      .aw-icon {
        font-size: $font-size-base;
      }
    }
  }
}