@import "../../../variables";

$file-icon-height: 150px;
$file-icon-width: 195px;
$file-icon-padding: 1rem;

.directory {
  cursor: pointer;
}

.file-list {
  tr {
    td.selection {
      width: 1%;
      padding-left: 0;
      padding-right: 0;
      vertical-align: middle;
    }

    border: transparent 2px solid;

    &:hover {

        .menu {
          display: flex;
        }

        #{$theme-dark} & {
          background-color: $color-gray-06 !important;
          td{
            border-top: $primary 2px solid;
            border-bottom: $primary 2px solid;
            }
          td:first-child{
            border-left: $primary 2px solid;
          }
          td:last-child{
            border-right: $primary 2px solid;
          }
        }
  
        #{$theme-light} & {
          background-color: $color-light-bg-box;
        }
      }
    }

  .file,
  .directory {
    width: 100%;
    display: flex;
    align-items: center;

    $thumb-height: 40px;

    .thumb {
      flex: 0 0 auto;
      width: calc(#{$thumb-height} * 16/9);
      height: $thumb-height;
      text-align: center;

      img{
        max-width: 100%;
        max-height: 100%;
      }

      .aw-icon {
        font-size: $thumb-height * .75;
      }
    }

    .file-info {
      margin-left: 1rem;
      overflow: hidden;

      .name,
      .metadata {
        text-align: left;
        display: block;
      }

      .metadata {
        font-size: $font-size-discreet;

        #{$theme-dark} & {
          color: $color-light-text-secondary;
        }

        #{$theme-light} & {
          color: $color-dark-text-secondary;
        }
      }
    }
  }

}

.file-grid {
  padding-top: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .file-item {
    width: $file-icon-width;
    height: $file-icon-height;
    padding: $file-icon-padding;
    border-radius: 5px;
    text-align: center;
    position: relative;

    .menu {
      position: absolute;
      top: -15px;
      right: 0px;
      display: none;

      .aw-icon {
        font-size: 1em;
      }
    }

    #{$theme-dark} & {
      border: transparent 2px solid;
    }
    &:hover {

        .menu {
          display: flex;
        }
  
        #{$theme-dark} & {
          border-color: $primary !important;
        }
  
        #{$theme-light} & {
          background-color: $color-light-bg-box;
        }
        }
  }

  .file,
  .directory {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    $thumb-height: 60px;

    .thumb {
      flex: 0 0 auto;
      width: calc(#{$thumb-height} * 16/9);
      height: $thumb-height;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .aw-icon {
        font-size: calc(#{$thumb-height} * 0.75);
      }
    }

    .file-info {
      flex: 0 0 auto;
      text-align: center;
      overflow: hidden;
      width: 100%;

      .name,
      .metadata {
        text-align: center;
        display: block;
      }

      .metadata {
        font-size: $font-size-discreet;

        #{$theme-dark} & {
          color: $color-light-text-secondary;
        }

        #{$theme-light} & {
          color: $color-dark-text-secondary;
        }
      }
    }
  }
}

.aw-popup.playback-selector {left: -2em !important}