@import "../../../variables";

.accordion {
  padding: 0.5rem;
  border-radius: 5px;

  .toggable {
    width: 100%;
    display: flex;
    align-items: center;

    .title {
      flex: 1 1 auto;
      text-align: left;

    }

    .aw-icon {
      flex: 0 0 auto;
    }
  }
}