@import "../../../variables";

.aw-status-btn-placeholder {
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 0.2rem;

  #{$theme-dark} & {
    border: 1px solid $color-dark-border-box;
  }

  #{$theme-light} & {
    border: 1px solid $color-light-border-box;
  }
}