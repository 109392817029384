@import "node_modules/@aviwest/ui-kit/dist/styles/variables";

:export {
    color_gray_05: $color-gray-01;
    color_gray_05: $color-gray-02;
    color_gray_05: $color-gray-03;
    color_gray_05: $color-gray-04;
    color_gray_05: $color-gray-05;
    color_gray_06: $color-gray-06;
    color_black: $color-black;
}