@import "../../../../variables";
.destination-row {
  margin-bottom: 0.5rem;
}

.enableEncoder {
  margin-bottom: 1rem;
}

.form-error {
  margin-bottom: 1rem;
  color: red;
  text-align: center;
}