@import "../../../variables";

.aw-input-selector {

  .aw-icon {
    vertical-align: middle;
  }

  .text-clip {
    max-width: 100px;
    display: inline-block;
    vertical-align: middle;
  }

  &:disabled{
    cursor: default;
  }

  .lock {
    position: absolute;
    right: 5px;
    font-size: 12px;
  }
}

.aw-popup .aw-popup-content.scrollable{
  max-height: 350px !important;
}
.aw-input-selector-popup.aw-popup {
  width: $select-popup-width;
  border-radius: calc(#{$spacer} / 4);
  border-width: 0;
  box-shadow: 0 5px 10px $color-black;
  background-color: $color-black;
  padding: 0;
  max-height: 350px;

  .aw-popup-content {
    padding: 0;
    height: 100%;
    background-color: $color-black;
    border-radius: calc(#{$spacer} / 4);

    ul {
      max-width: none;
      li {
        padding: 0;
        height: 28px;
        margin: calc(#{$spacer} / 4);
        +li {
          margin-top: 0;
        }

        button {
          width: 100%;
          height: 100%;
          border-radius: calc(#{$spacer} / 4);
          border: transparent 2px solid;
          background-color: $color-gray-05 !important;
          color: white !important;
          outline: 0;

          &:disabled {
            color: $color-gray-02 !important;
          }

          &:hover:not(:disabled) {
            background-color: $color-gray-06 !important;
            border: $color-primary 2px solid;
          }

          &:active, &.active {
            background-color: $color-primary !important;
          }
        }
      }
    }
  }
}

.input-selection {
  display: flex;
  align-items: center;
  padding: 0 .25rem !important;
  border: transparent 2px solid;

  >.id {
    display: flex;
    flex: 1;
    font-weight: 700;
    white-space: nowrap;
  }

  >.name {
    padding: 0 .5rem;
  }

  >.status {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .badge-label {
      flex-grow: 0 !important;
    }
  }

  .aw-icon {
    vertical-align: middle;
    margin-right: $spacer / 4;;
  }
}

.list{
  overflow-anchor: none !important;
}