@import "../../../variables";

.aw-circular-badge {
  display: inline-block;
  padding: 0.25rem;
  border-radius: 50%;
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  text-align: center;


  .text-container {
    position: relative;
    min-height: 0.5rem;
    min-width: 0.5rem;
    font-size: 0.5rem;
    line-height: 0.5rem;
    font-weight: 600;

    .text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .nav-item & {
    color: white;
    text-align: center;
    margin-right: 0;
    margin-left: 0.25rem;
  }

  button & {
    position: absolute;
    margin-right: unset;
    right: -0.25rem;
    top: -0.25rem;
  }

  button sub & {
    top: 0.25rem;
  }

  &.primary {
    #{$theme-dark} & {
      background-color: $color-primary;
    }

    #{$theme-light} & {
      background-color: $color-primary;
    }
  }

  &.secondary {
    background-color: $color-gray;
  }

  &.info {
    background-color: $color-info;
  }

  &.success {
    background-color: $color-success;
  }

  &.danger {
    background-color: $color-error;
  }

  &.warning {
    background-color: $color-warning;
  }
}