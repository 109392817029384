@import "variables";
@import "node_modules/@aviwest/ui-kit/dist/styles/index.scss";

@import "./components/common/circular-badge/index";
@import "./components/common/dashboard-filters/index";
@import "./components/common/empty/index";
@import "./components/common/input-type-badge/index";
@import "./components/common/footer/index";
@import "./components/common/sidebar/index";
@import "./components/common/player/index";
@import "./components/common/status-button/index";
@import "./components/common/ip-profile-metadata/index";
@import "./components/common/license-modal/index";
@import "./components/common/file-explorer/index";
@import "./components/common/player-seeker/index";
@import "./components/common/input-selector/index";
@import "./components/common/accordion/index";
@import "./components/common/language-modal/index";
@import "./components/common/streamhub-logo/index";
@import "./components/layouts/index";
@import "./components/pages/dashboard/index";
@import "./components/pages/dashboard/input-section/input-settings-modal/index";
@import "./components/pages/dashboard/output-section/output/index";
@import "./components/pages/dashboard/output-section/output-hardware-settings-modal/index";
@import "./components/pages/files/index";
@import "./components/pages/login/index";
@import "./components/pages/settings/index";
@import "./components/pages/tools/index";

html,
body,
#root {
  height: 100%;
}

ul {
  list-style: none;
  padding-left: 0;
}

.aw-icon.primary {
  color: $color-primary;
}

.text-primary {

  #{$theme-dark} & {
    color: $color-primary !important;
  }

  #{$theme-light} & {
    color: $color-primary !important;
  }
}

.text-secondary {
  #{$theme-dark} & {
    color: $color-dark-text-secondary !important;
  }

  #{$theme-light} & {
    color: $color-light-text-secondary !important;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.text-clip {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.full-height {
  height: 100%;
}

.floating-right {
  float: right;
}

.flex-right {
  flex: 1 1 auto;
  display: flex;

  &:before {
    content: '';
    flex: 1 1 auto;
  }
}

.no-padding {
  padding: 0 !important;
}

.no-top-padding {
  padding-top: 0 !important;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-right-padding {
  padding-right: 0 !important;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-top-margin {
  margin-top: 0 !important;
}

.no-right-margin {
  margin-right: 0 !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.no-left-margin {
  margin-left: 0 !important;
}
.dropdown-menu {
  z-index: 1003;
}
.dropdown-item {
  .aw-icon {
    vertical-align: bottom;
    margin-right: 0.3rem;
  }
}
.dropdown-item.active {
    color: $color-primary !important
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.form-group {
  .indicator {
    margin-top: 0.2rem;
    font-size: $font-size-discreet;

    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }
  }
}

.form-check {
  margin-bottom: 1rem;
}

.pretty-scroll {
  padding-right: 0.5rem;
  //background:
  //        linear-gradient(white 30%, hsla(0,0%,100%, 0)),
  //        linear-gradient(hsla(0,0%,100%,0) 10px, white 70%) bottom,
  //        radial-gradient(at top, rgba(0,0,0,0.2), transparent 70%),
  //        radial-gradient(at bottom, rgba(0,0,0,0.2), transparent 70%) bottom;
  //background-repeat:no-repeat;
  //background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
  //background-attachment:local, local, scroll, scroll;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: $color-gray-04;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    #{$theme-dark} & {
      background-color: $color-secondary;
    }

    #{$theme-light} & {
      background-color: $color-secondary;
    }
  }
}

.aw-theme-dark .layout-section .section-header {
  border-bottom: none;
}

//.aw-popover {
//  background-color: black;
//  padding: 0.5rem;
//  min-width: 100px;
//  color: #FFFFFF;
//
//  &.bs-popover-right {
//    .arrow:after {
//      border-right-color: black;
//    }
//  }
//
//  &.bs-popover-left {
//    .arrow:after {
//      border-left-color: black;
//    }
//  }
//
//  &.bs-popover-bottom {
//    .arrow:after {
//      border-bottom-color: black;
//    }
//  }
//
//  &.bs-popover-top {
//    .arrow:after {
//      border-top-color: black;
//    }
//  }
//}

.table {
  margin-top: .5rem;
  tr {
    .actions-menu {
      position: relative;
      display: table-cell;

      .menu {
        display: none;
        position: absolute;
        top: -25px;
        right: 0;
        z-index: 1000;

        .aw-icon {
          font-size: 1em;
        }
      }
    }

    &:hover {
      .menu {
        display: flex;
      }
    }
  }

  tr.disabled {

    .name,
    .protocol,
    .metadata {
      opacity: 0.3;
    }
  }

  td.text-center {
    text-align: center;
  }
  td.right {
    text-align: right;
  }
  td.name {
    width: 20%;
  }
  td.protocol {
    font-size: $font-size-discreet;

    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }

  }
  td.metadata {
    width: 70%;
    font-size: $font-size-discreet;

    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }

    .metadata-container {
      display: flex;
      position: relative;
      min-width: 0;

      .metadata-value {
        position: absolute;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  td.discreet {
    font-size: $font-size-discreet;

    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }

  }
  td.actions {
    text-align: right;
    width: 1%;
  }
}

.menu {
  text-align: center;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 3px 0;

  .btn {
    flex: 0 0 auto;
    margin: 0 5px;
  }

  @include aw-theme(border-color, $color-dark-border-box, $color-light-border-box);
  @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
}

// Slider

.rc-slider.aw-theme {
  .rc-slider-rail {
    background-color: $color-gray-04;
  }

  .rc-slider-track {
    background-color: $color-secondary;
  }

  .rc-slider-handle {
    background-color: $color-primary;
    border: none;

    &:focus {
      box-shadow: 0 0 10px 2px $color-primary;
    }
  }

  &.rc-slider-disabled {
    background-color: transparent;
    opacity: 0.5;
  }
}

.clickable {
  cursor: pointer;
}

.draggable {
  cursor: move;
}

.flex-placeholder {
  &.expand {
    flex: 1 1 auto;
  }
}

.aw-profile-selector {
  .text-clip {
    max-width: 100px;
    display: inline-block;
    vertical-align: middle;
  }
}

.aw-layout-selector {
  margin-left: 0.5rem;

  button > .aw-icon {
    @include aw-theme(color, $color-dark-text-primary, $color-light-text-primary);
  }

  button.active > .aw-icon {
    color: $color-primary !important;
  }
}

.file-section,
.encoders {
  .aw-layout-selector {
    margin-right: 0.5rem;
  }
}


.btn.basic > .aw-icon {
  font-size: 16px;
}

#{$theme-dark} .btn.btn-secondary.square {
  border: transparent 2px solid;
}
#{$theme-dark} .btn.btn-secondary.square:not(.disabled):not(:disabled):hover {
  background-color: $color-gray-06;
  border-color: $primary !important;
}

.aw-popup {
  z-index: 1030;

  .aw-popup-content {
    ul {
      &.popup-condensed {
        display: flex;
        flex-flow: row wrap;
        >li {
          width: 136px;
          margin: calc(#{$spacer} / 4) !important;
        }
      }
    }
  }
}

svg.aw-icon.aw-ip_input text {
  font-size: 7px;
}

.help-layout {

  color: $color-gray-02;
  .title {
    color: $color-gray-01;
  }
  .primary {
    color: $color-primary;
  }
  .note {
    background-color: $color-gray-04;
    font-style: italic;
    padding: .75em;
    margin: .75em;
    border-radius: 8px;
  }
}

.aw-loader {
  z-index: 998 !important;
}