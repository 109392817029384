@import "../../../../variables";

.settings-section.file-transfer {

  .aw-table {

    td {

      &.metadata {
        width: 35%;
      }

      &.test {
        width: 35%;
        font-size: $font-size-discreet;
        color: $color-dark-text-secondary;

        .loader {
          .text {
            margin-left: 0.5rem;
            vertical-align: top;
          }
        }

        .success {
          color: $color-success;
        }

        .error {
          color: $color-error;
        }
      }
    }

  }

}