@import "../../../../variables";

.log-filters {
  min-width: 120px;

  .aw-popup-header {
    font-size: initial;
  }

  .form-check-label {
    margin-left: 1.5rem;
  }
}

.aw-logs {

  li {
    .timestamp {
      font-size: $font-size-discreet;
      color: $color-dark-text-secondary;
      margin-right: 1rem;
    }
  }
}

.label {
  &.info {
    color: $color-blue-02
  }
  &.warning {
    color: $color-warning;
  }
  &.danger {
    color: $color-error;
  }
  &.error {
    color: $color-error;
  }
}