@import "../../../variables";

.ip-profile-metadata {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .metadata-section-empty {
    @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
    flex: 1 0 auto;

    .profile:hover &, .profile.disabled & {
      background-color: $color-gray-06;
    }
  }

  .metadata-section {
    @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
    padding: calc(#{$spacer}/2);
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    max-width: 100%;

    .profile:hover &, .profile.disabled &  {
      background-color: $color-gray-06;
    }

    +.metadata-section{
      margin-top: calc(#{$spacer}/8);
    }

    .metadata-type {
      font-weight: 700;
    }

    .local-uri,
    .public-uri,
    .uri,
    .port,
    .video,
    .audio,
    .sdi-index,
    .sdi-channels {
      flex: 1 1 auto;
    }

    .button-copy {
      flex: 0 0 auto;
    }

    $local-uri-label-width: 5rem;
    $public-uri-label-width: 5rem;
    $uri-label-width: 3rem;
    $port-label-width: 3rem;
    $video-label-width: 3.5rem;
    $audio-label-width: 3.5rem;
    $sdi-index-label-width: 3rem;
    $sdi-channels-label-width: 5.7rem;

    .metadata-type {
      &.uri {
        flex: 0 0 $uri-label-width;
      }
      &.local-uri {
        flex: 0 0 $local-uri-label-width;
      }
      &.public-uri {
        flex: 0 0 $public-uri-label-width;
      }
      &.port {
        flex: 0 0 $port-label-width;
      }
      &.video {
        flex: 0 0 $video-label-width;
      }
      &.audio {
        flex: 0 0 $audio-label-width;
      }
      &.sdi-index {
        flex: 0 0 $sdi-index-label-width;
      }
      &.sdi-channels {
        flex: 0 0 $sdi-channels-label-width;
      }
    }
  }
}