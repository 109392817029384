@import "../../../../../variables";

//.input-settings-modal.menu {
//  margin-bottom: 1rem;
//}

.input-settings-form {

  .aw-heading .title{
    line-height: initial;
    padding-top: 0;
    padding-bottom: 1rem;
  }

  #inputDevice,
  #outputDevice {
    margin: 0;
    flex-flow: wrap;
    >label {
      min-width: 4.4em;
    }
  }

  .push-pull {
    display: flex;
    align-items: center;

    .push,
    .pull {
      padding: 0 0.5rem;
      flex: 1 1 auto;
    }

    .push {
      text-align: right;
    }
    .aw-switch {
      flex: 0 0 auto;
    }

    .switch .switch-checkbox + .switch-label {
      background-color: $color-gray-01;
      border-color: $color-gray-01;
    }

    .switch .switch-checkbox + .switch-label:before {
      border-color: $color-gray-01;
    }
  }
}