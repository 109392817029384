@import "../../../../../variables";

.output-custom-pattern-form {

  margin-top: 0.5rem;

  .preview {
    max-width: 200px;

    img {
      width: 100%;
    }
  }

}

.output-custom-pattern-form {

  border-top: 1px solid $color-gray-06;
  padding-top: 1rem;
  button {
    float: right;
  }
}