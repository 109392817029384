@import "../../../variables";

$track-height: 100%;
$track-color: $color-primary;
$thumb-width: 0;
$thumb-height: 0;
$thumb-color: #fff;
$thumb-border-width: 0;
$thumb-border-color: #999;
$track-default-color: $color-overlay-bg;

input[type="range"].player-seeker {
  margin: auto;
  background: $track-default-color;
  -webkit-appearance: none;
  overflow: hidden;
  height: $track-height;
  width: 100%;
  cursor: pointer;
  border-radius: 0;

  ::-webkit-slider-runnable-track {
    background: $track-default-color;
  }

  /*
   * 1. Set to 0 width and remove border for a slider without a thumb
   */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: $thumb-width; /* 1 */
    height: $thumb-height;
    background: $thumb-color;
    border: $thumb-border-width solid $thumb-border-color; /* 1 */
    box-shadow: -100vw 0 0 100vw $track-color;
  }

  &::-moz-range-track {
    height: $track-height;
    background: $track-default-color;
  }

  &::-moz-range-thumb {
    background: $thumb-color;
    height: $thumb-height;
    width: $thumb-width;
    border: $thumb-border-width solid $thumb-border-color;
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw $track-color;
    box-sizing: border-box;
  }

  &::-ms-fill-lower {
    background: $track-color;
  }

  &::-ms-thumb {
    background: $thumb-color;
    border: $thumb-border-width solid $thumb-border-color;
    height: $thumb-height;
    width: $thumb-width;
    box-sizing: border-box;
  }

  &::-ms-ticks-after {
    display: none;
  }

  &::-ms-ticks-before {
    display: none;
  }

  &::-ms-track {
    background: $track-default-color;
    color: transparent;
    height: $track-height;
    border: none;
  }

  &::-ms-tooltip {
    display: none;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}
