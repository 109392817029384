@import "../../../variables";

.languages {

  .language {
    font-size: 1.2rem;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      #{$theme-dark} & {
        color: $color-primary;
      }

      #{$theme-light} & {
        color: $color-primary;
      }
    }

  }

}