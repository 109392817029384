@import "../../../../variables";

.config-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.5rem 0;
}

.indicator{
  font-size: 0.8rem !important;
}